import React, { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { Avatar, CardHeader, Tooltip } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import cookie from 'react-cookies';
import Configuration from '../../../../../../service/configuration';
import DataServices from '../../../../../../service/data-services';
import Resources from '../../../../../../service/resources';
import { NavLink as RouterLink } from 'react-router-dom';
import { green,blueGrey, orange } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: 56,
    /*flexDirection: 'column',
    alignItems: 'left',
    minHeight: 'fit-content'*/
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >

    <RouterLink {...props} />

  </div>
));
const Profile = props => {
    const { className, ...rest } = props;
    const config = new Configuration();
    const classes = useStyles();
    const dataService = new DataServices( );
    var userData = dataService.getCurrentUserData();
    var auth = dataService.getAuthoritiesData();
    const resources = new Resources( );


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
    {(userData && userData['avatar']) &&
        <CardHeader
            title={userData?userData['name']:'Please logout'}
            subheader={userData? (userData['roleName']==='Employee'? 'Deliveryman': (userData['roleName']==='OS'?'Merchant':userData['roleName']) ) :''}
            avatar={
                    <Tooltip title="My Profile" arrow>
                        <Avatar src={resources.BACKEND_SIDE_BASE_URL + config.SERVICE_NAME_IMAGE + config.SERVICE_ACTION_IMAGE_DOWNLOAD + userData['avatar']} />
                    </Tooltip>
                }
         />
    }

    {(userData && !userData['avatar']) &&
        <CardHeader
            title={userData?userData['name']:'Please logout'}
            subheader={userData? (userData['roleName']==='Employee'? 'Deliveryman': (userData['roleName']==='OS'?'Merchant':userData['roleName']) ) :''}
            avatar={
                    <Tooltip title="My Profile" arrow>
                        <Avatar style={{backgroundColor: orange[700]}}>
                            {userData?userData['name'][0].toUpperCase() :'U'}
                        </Avatar>
                    </Tooltip>
                }
         />
    }

    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
