/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, Link, Badge } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AttachMoney,
LocalShipping,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Settings as SettingsIcon,
Dashboard, Redeem, GetApp,
DoneAll,BorderAll, PeopleAlt,VerifiedUser,
ContactPhone, AddShoppingCart, AccountTree, Storefront as StorefrontIcon,
BusinessCenter as BusinessCenterIcon,
EventNote as EventNoteIcon, AccountBalance as AccountBalanceIcon,
ListAlt as ListAltIcon,ShoppingCart as ShoppingCartIcon,
Assignment as AssignmentIcon, ShoppingBasket} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next'
import { Inventory as InventoryIcon, PointOfSale as PointOfSaleIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: colors.black,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
 itemNested2: {
   display: 'flex',
   paddingTop: 0,
   paddingBottom: 0,
   paddingLeft: theme.spacing(6)
 },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );
    var userData = dataService.getCurrentUserData();
    var posPrivilege = dataService.getCurrentUserPOSPrivilege();


    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openDelivery, setOpenDelivery] = React.useState(window.location.pathname.includes('delivery'));
    const handleClickDelivery = () => {
      setOpenDelivery(!openDelivery);
    };
    const [openFinance, setOpenFinance] = React.useState(window.location.pathname.includes('finance'));
    const handleClickFinance = () => {
        setOpenFinance(!openFinance);
    };
    const [openUsers, setOpenUsers] = React.useState(window.location.pathname.includes('user'));
    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
    };
    const [openZone, setOpenZone] = React.useState(window.location.pathname.includes('pricing'));
    const handleClickZone = () => {
        setOpenZone(!openZone);
    };
    const [openReports, setOpenReports] = React.useState(window.location.pathname.includes('report'));
    const handleClickReports = () => {
        setOpenReports(!openReports);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('settings'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openAddress, setOpenAddress] = React.useState(window.location.pathname.includes('address'));
    const handleClickAddress = () => {
        setOpenAddress(!openAddress);
    };
    const [openOrganization, setOpenOrganization] = React.useState(window.location.pathname.includes('org'));
    const handleClickOrganization = () => {
        setOpenOrganization(!openOrganization);
    };
    const [openPickup, setOpenPickup] = React.useState(window.location.pathname.includes('way'));
    const handleClickPickup = () => {
        setOpenPickup(!openPickup);
    };
    const [openMerchant, setOpenMerchant] = React.useState(window.location.pathname.includes('merchant'));
    const handleClickMerchant = () => {
        setOpenMerchant(!openMerchant);
    };
    const [openDeliveryman, setOpenDeliveryman] = React.useState(window.location.pathname.includes('deman'));
    const handleClickDeliveryman = () => {
        setOpenDeliveryman(!openDeliveryman);
    };
    const [openReporting, setOpenReporting] = React.useState(window.location.pathname.includes('report'));
    const handleClickReporting = () => {
        setOpenReporting(!openReporting);
    };
    const [openPos, setOpenPos] = React.useState(window.location.pathname.includes('pos'));
    const handleClickPos = () => {
      setOpenPos(!openPos);
    };
    const [openAccounting, setOpenAccounting] = React.useState(window.location.pathname.includes('accounting_'));
    const handleClickAccounting = () => {
      setOpenAccounting(!openAccounting);
    };
    const [openAccounts, setOpenAccounts] = React.useState(window.location.pathname.includes('accounts_'));
    const handleClickAccounts = () => {
      setOpenAccounts(!openAccounts);
    };
    const [openTransaction, setOpenTransaction] = React.useState(window.location.pathname.includes('transaction_'));
    const handleClickTransaction = () => {
      setOpenTransaction(!openTransaction);
    };
    const [openFinancial, setOpenFinancial] = React.useState(window.location.pathname.includes('financial_'));
    const handleClickFinancial = () => {
      setOpenFinancial(!openFinancial);
    };

    const [openPosOrder, setOpenPosOrder] = React.useState(window.location.pathname.includes('possale'));
    const handleClickPosSale = () => {
      setOpenPosOrder(!openPosOrder);
    };
    const [openPosPurchase, setOpenPosPurchase] = React.useState(window.location.pathname.includes('pospurchase'));
    const handleClickPosPurchase = () => {
      setOpenPosPurchase(!openPosPurchase);
    };

    const hrmLogin = () =>{
        dataService.retrieve( config.SERVICE_NAME_USER, config.SERVICE_ACTION_USER_HR_AUTHORIZATION).then(items => {
            if( items && items.success ) {
                var anchor = document.createElement('a');
                anchor.href = 'https://hrdemo.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://ghr.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://hrtest.quickdoor2door.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.target="_blank";
                anchor.click();
            }else{
                alert(items.message);
            }
        });
    }

    const [openShop, setOpenShop] = React.useState(window.location.pathname.includes('shops'));
    const handleClickShop = () => {
        setOpenShop(!openShop);
    };

    const [openProduct, setOpenProduct] = React.useState(window.location.pathname.includes('products'));
    const handleClickProduct = () => {
        setOpenProduct(!openProduct);
    };

    const [openInventory, setOpenInventory] = React.useState(window.location.pathname.includes('inventories'));
    const handleClickInventory = () => {
        setOpenInventory(!openInventory);
    };

  return (
          <div>

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickShop();}}>
              {openShop ? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ShoppingBasket />}</div>
                Shops management
              </Button>
            </ListItem>

            <Collapse in={openShop} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/shops_list'} >
                        Shops
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickProduct();}}>
              {openProduct? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ShoppingBasket />}</div>
                Product management
              </Button>
            </ListItem>

            <Collapse in={openProduct} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_normal_item_list'} >
                        Items
                      </Button>
                    </ListItem>

                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/products_category_list'} >
                        Categories
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>

        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickInventory();}}>
              {openInventory? <MenuOpen /> : <MenuClose />}
              <Button activeClassName={classes.active} className={classes.button} >
                <div className={classes.icon}>{<ShoppingBasket />}</div>
                Inventory management
              </Button>
            </ListItem>

            <Collapse in={openInventory} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/inventories_supplier_list'} >
                        Suppliers
                      </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>

    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                onClick={event =>{dataService.removeTokenCookie();}}
            >
          <div className={classes.icon}>{<LogoutIcon />}</div>
            {t('menu.logout')}
          </Button>
        </ListItem>
    </List>

    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}